import React from 'react';
import './blog.scss';
import {blogData} from '../../../assets/servicedata';

function Blog() {
    return (
            <section id='blog' className='blog'>
                <div className="container">
                    <div className='blog p-40'>
                        <div className="section-text">
                            <h2>updates & <span>events</span></h2>
                            <div id="big-line-full"></div>
                        </div>

                        {blogData.map((post,index)=>{
                            return(
    
                                <div key={index}>

                                    <div  className="row">
                                        <div className="col">
                                            <h3 id='h3'>{post.title}</h3>
                                            <p>{post.text}</p>
                                        </div>
                                        <div className="col">
                                            <div className='img'>
                                                <img src={post.image} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="big-line-full"></div>
                                </div>
                    
                                )
                        })}


                    </div>
                </div>
                  
            </section>
    )
}

export default Blog