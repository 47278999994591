import React from 'react';
import './footer.scss';
import { iconsData } from '../../assets/servicedata';
import { BiUpArrowCircle } from 'react-icons/bi';

function Footer() {
    return (

        <footer id='contact'>
            <div id="big-line-full"></div>
            <div className="container-fluid">
                <div className="logo">
                    <img id='logo' src="/Images/logo.png" alt="logo" />
                </div>

                <div className="footer-content">

                    <div className="row">
                        <div className="col">
                            <h4 id='main-text-align'>address</h4>
                            <div id='main-text-align'>
                            <p>Divine Intervention Entertainment
                                </p>
                                <p>407 Bay Square 13</p>
                                <p>Business Bay Dubai</p>
                                <p>United Arab Emirates</p>
                            </div>
                                
                        </div>
                        <div className="col">
                            <h4>call us</h4>
                            <a className='cl-white' href="tel:97145514225">+971 45514225</a>
                        </div>
                        <div className="col">
                            <h4>email</h4>
                            <a className='cl-white' href="mailto:info@divine-ie.com">info@divine-ie.com</a>
                        </div>
                       
                    </div>

                    <div className="socil-icons">
                        {iconsData.map((icon, index) => {
                            return (
                                <div key={index} className="social-icon">
                                    <a href={icon.url} target="_blank" rel="noopener noreferrer">
                                    {icon.icon}
                                    </a>
                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>
            <div className="home-arrow">
                <div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <BiUpArrowCircle className='home-arrow-icon' />
                </div>
            </div>
        </footer>
    )
}

export default Footer