import React, { useEffect, useState } from 'react';
import './team.scss'
import { TeamData } from "../../../assets/servicedata";
import useWinodw from '../../../customhook/useWinodw';

function Team() {

    const [sizeVal, setSizeVal] = useState(false);
    const size = useWinodw();
    useEffect(() => {

        if (size[1] < 850) {
            setSizeVal(true)
        }

    }, [size])
    return (
        <>
            <section>
                <div className="container">
                    <div className="team p-40">
                        <div className="Team-text">
                            <h2> <span>Our</span> Team</h2>
                        </div>
                        {/* responsive for mobile  */}
                        {sizeVal ? (
                            <div className="row">
                                {TeamData.map((e, index) => (
                                    <div className="col" id='col-main' key={index}>
                                        <div className="card" id='card-main'>
                                            <div className="card-img">
                                                <img src={e.img} alt={e.name} />
                                            </div>
                                            <div className="card-body">
                                                <h4>{e.name}</h4>
                                                <p>{e.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="row">
                                <div className="row">

                                    {TeamData.map((e, index) => (
                                        <>
                                            {e.position ? (

                                                <div className="col" key={index}>
                                                    <div className="card">
                                                        <div className="card-img">
                                                            <img src={e.img} alt={e.name} />
                                                        </div>
                                                        <div className="card-body">
                                                            <h4>{e.name}</h4>
                                                            <p>{e.title}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            ) : (
                                                <> </>
                                            )}
                                        </>
                                    ))}
                                </div>
                                <div className="row">
                                    {TeamData.map((e, index) => (
                                        <>
                                            {e.position ? (
                                                <> </>
                                            ) : (
                                                <div className="col" key={index}>
                                                    <div className="card">
                                                        <div className="card-img">
                                                            <img src={e.img} alt={e.name} />
                                                        </div>
                                                        <div className="card-body">
                                                            <h4>{e.name}</h4>
                                                            <p>{e.title}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                        )}


                    </div>
                </div>
            </section>
            <div id="big-line-full"></div>
        </>
    );
}

export default Team;