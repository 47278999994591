import React from 'react';
import './topbar.scss';

function Topbar() {
  return (

    <section className='topbar'>
      <div className="bigtext">
        <h1 className='words'>
          <span>d</span>
          <span>i</span>
          <span>v</span>
          <span>i</span>
          <span>n</span>
          <span>e</span>
        </h1>
        <p>intervention</p>
      </div>


      <div id="single-line">
          <div id="arrowAnim">
            <div className="arrowSliding">
              <div className="arrow"></div>
            </div>
            <div className="arrowSliding delay1">
              <div className="arrow"></div>
            </div>
            <div className="arrowSliding delay2">
              <div className="arrow"></div>
            </div>
            <div className="arrowSliding delay3">
              <div className="arrow"></div>
            </div>
        </div>

        <div className="section-next-text">
          {/* <h3>our work</h3> */}
        </div>

      
      </div>

    </section>
  )
}

export default Topbar