import React from 'react'
import Infinity from './Infinity.svg'
function Loder() {
    return (
        <div className='loder'>
            <img src={Infinity} alt="newsvg" width={300} height={300} />
        </div>
    )
}

export default Loder