import React from 'react';
import './about.scss';

function About() {
    return (
        
            <section id='about'>
        <div className="container">
                <div className="about">
                    <div className="fixed">
                        <div className="sticky">
                            <h2>transforming your brands</h2>
                        </div>
                    </div>
                    <div className="scroll">
                        <div>
                            <p>Welcome! We are Divine Intervention Entertainment (D.I.E), a young, fast-growing and proudly independent marketing agency.</p>
                        </div>
                        <div>
                            <p>We pride ourselves on working closely with our partners to create fiercely innovative and well-researched strategic marketing campaigns.</p>
                        </div>
                        <div>
                            <p>We are committed to helping startup companies and individuals compete in today’s cutthroat market by devising creative strategies with cost effective solutions.</p>
                        </div>
                        <div>
                            <p>Our diverse team have a breadth of expertise that make our strategy adaptable and ever evolving to suit your needs.</p>
                        </div>
                    </div>
                </div>
        </div>
            </section>
    )
}

export default About