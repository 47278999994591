import React from 'react'
import { cultureData } from "../../../assets/servicedata";
import './cultures.scss';

function Cultures() {


    return (
        <>
            <section id='bg-white'>
                <div className="container">


                    <div className="p-40 culture-text">
                        <h2>our <span>culture</span></h2>
                    </div>
                    <div className='culture'>


                        {cultureData.map((e, index) => (
                            <div className="container-1" key={index}>
                                <div className="card">
                                    <div className="front">
                                        <div className="content">
                                            <h3>{e.name}</h3>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="content">
                                            <p>
                                                {e.descrption}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cultures;