import React from 'react';
import Slider from "react-slick";
import './company.scss';
import { companytData } from '../../../assets/servicedata';

function Company() {

  const settings = {
    className: "carousel-settings",
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='carasuole'>
      <Slider {...settings}>
        {companytData.map((e, index) => {
          return (
              <div key={index} className='slide-items'>
                <img src={e.img} alt={e.name} />
              </div>
          )
        })}
      </Slider>



    </div>
  )
}

export default Company