import React, { useState, useEffect } from 'react';
import './navbar.scss';
import { FaInstagram, FaFacebook, FaLinkedinIn, FaBars, FaTimes } from 'react-icons/fa';
import useScoll from '../../../customhook/useScoll';
function Navbar() {

    const [inactive, setInactive] = useState(false);
    const [navClassList, setNavClassList] = useState([]);

    // const { scrollDirection } = useScoll();
    const scroll = useScoll();


    useEffect(() => {
        const _classList = [];

        if (scroll.y > 90 && scroll.y - scroll.lastY > 0)
            _classList.push("scoll-nav-inactive");

        setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);

    return (

        <header className={inactive ? "main-active" : "main-inactive"}  id={navClassList.join("")}>

            <nav className="navbar scoll-nav-active" id={navClassList.join("")}>
                <div className="logo mobile">
                    <a href="/">
                        <img src="/Images/logo.png" alt="logo" />
                    </a>
                    <div id='mobile-button'>

                        {inactive ? (
                            <FaTimes size="2rem" onClick={() => setInactive(!inactive)} />
                        ) : (
                            <FaBars size="2rem" onClick={() => setInactive(!inactive)} />
                        )}
                    </div>
                </div>

                <div id="nav-items" className="nav-items">
                    <a href="/">home</a>
                    <a href="#services">services</a>
                    <a href="#about">about</a>
                    <a href="#blog">blog</a>
                    <a href="#contact">Contact</a>
                </div>

                <div className="socials">
                    <a href="https://instagram.com/dieofficialdxb?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">
                    <FaInstagram className='icons' />
                    </a>
                    <a href="https://www.linkedin.com/company/dieofficialdxb/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedinIn className='icons' />
                    </a>
                    <a href="https://www.facebook.com/dieofficialdxb" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className='icons' />
                    </a>
                </div>



            </nav>
        </header >
    )
}

export default Navbar