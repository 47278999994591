import React from 'react';
import {FaWhatsapp} from 'react-icons/fa';
import './chat.scss';

function Chat() {

  return (

    <div>
        <a href="https://wa.me/" className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer">
            <FaWhatsapp className='whatsapp-icon' />
        </a>
    </div>
  )
}

export default Chat