import React from 'react';
import './service.scss';
import { data } from '../../../assets/servicedata';

function Service() {



    return (
        <>

            <section id='services'>
                <div className="container">

                    <div id="big-line-full"></div>
                    <div className="service p-40">

                        <div className="section-text">
                            <h2>WHAT WE DO</h2>
                        </div>

                        <div className="row">

                            {data.map((e, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <div className="icons">
                                            {e.icon}
                                        </div>
                                        <h3>{e.name}</h3>
                                        <div>
                                            <p>{e.descrption}</p>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service
