import { FaInstagram, FaFacebook, FaVideo, FaLinkedin, FaMusic } from 'react-icons/fa';
import { CgWebsite, } from 'react-icons/cg';
import { GiGamepad, } from 'react-icons/gi';
import { AiOutlineAudit, } from 'react-icons/ai';
import { MdOutlineAnalytics, MdDesignServices, MdPersonSearch, MdEventAvailable } from 'react-icons/md';

export const data = [
    {
        name: 'VIDEO and PHOTO CONTENT CREATION',
        descrption: 'Expert creative direction and editing to create content that matches your vision and exceeds your expectation ',
        icon: <FaVideo className='icon' />
    },
    {
        name: 'CAMPAIGN DESIGN & MANAGEMENT',
        descrption: 'We use creative design solutions to establish aesthetic and practical campaigns that disrupt the status-quo of the current market and best deliver your philosophy',
        icon: <MdDesignServices className='icon' />
    },
    {
        name: 'Marketing research and analytics',
        descrption: 'Don’t know why your posts and ads aren’t gaining reactions? We frequently monitor and evaluate online performance and identify the responsiveness of different demographics to key trends, adapting our strategy to continuously improve results',
        icon: <MdOutlineAnalytics className='icon' />
    },
    {
        name: 'DIGITAL MARKETING',
        descrption: 'Discovering and developing creative marketing solutions to boost your online presence using the most popular and current platforms including TikTock and Instagram',
        icon: <AiOutlineAudit className='icon' />
    },
    {
        name: 'TALENT MANAGEMENT ',
        descrption: 'Trying to grow your following as an influencer or brand ambassador? Let’s boost your social media presence with our updated digital marketing strategy and give you the opportunity to establish your talent/identity and expand your reach',
        icon: <MdPersonSearch className='icon' />
    },
    {
        name: 'Web design',
        descrption: ' Our balanced combination of aesthetics and practicality enable us to create modern and cutting-edge web designs that do your product or service justice.',
        icon: <CgWebsite className='icon' />
    },
    {
        name: 'Music',
        descrption: 'Aiding upcoming artists in the production and distribution of their music.',
        icon: <FaMusic className='icon' />
    },
    {
        name: 'Gaming',
        descrption: "Hosting online gaming events as well as live activations and producing gaming IP's.",
        icon: <GiGamepad className='icon' />
    },
    {
        name: 'Events MANAGEMENT',
        descrption: 'Creating, organizing and managing events for all categories of clients. Every event is unique and tailored to our clients requirements.',
        icon: <MdEventAvailable className='icon' />
    },
]

export const cultureData = [
    {
        id: 1,
        name: "Passionate",
        descrption: "We work hard to imagine and deliver the service that will exceed your expectations"

    },
    {
        id: 2,
        name: "creative",
        descrption: "Our team value the importance of creative thought, using it to envision a campaign custom tailored to your needs"

    },
    {
        id: 3,
        name: "excellence",
        descrption: "Our team are highly responsive to your requests and open minded to improvements that will optimize your experience as our partner"

    }
]

export const blogData = [
    {
        title: "UAE Pro League’s 2021-22 E-Sports Tournament",
        text: "The E-Sports tournament that gave the winner an opportunity to represent the UAE Pro League at the EA SPORTS FIFA 22 Global Series Playoffs.",
        image: '/Images/work/proleagulogo.jpg'
    },

]

export const iconsData = [
    {
        icon: <FaInstagram className='icon' />,
        url: "https://instagram.com/dieofficialdxb?utm_medium=copy_link"
    },
    {
        icon: <FaFacebook className='icon' />,
        url: "https://www.facebook.com/dieofficialdxb"
    },
    {
        icon: <FaLinkedin className='icon' />,
        url: "https://www.linkedin.com/company/dieofficialdxb/"
    },
]

export const companytData = [
    {
        img: '/Images/image 1.png',
        name: 'alter'
    },
    {
        img: '/Images/image 2.png',
        name: 'alter'
    },
    {
        img: '/Images/image 3.png',
        name: 'alter'
    },
    {
        img: '/Images/image 4.png',
        name: 'alter'
    },
    {
        img: '/Images/image 5.png',
        name: 'alter'
    },
    {
        img: '/Images/image 6.png',
        name: 'alter'
    },
    {
        img: '/Images/image 7.png',
        name: 'alter'
    },
    {
        img: '/Images/image 8.png',
        name: 'alter'
    },
    {
        img: '/Images/image 9.png',
        name: 'alter'
    },
]

export const workData = [

    {
        img: "Images/work/final.mp4",
        name: "name",
    },
    {
        img: "Images/work/Dimmis video.mp4",
        name: "name",
    },
    {
        img: "Images/work/gws trailer for jack1.mp4",
        name: "name",
    },
    // {
    //     img: "Images/work/Untitled(1).mp4",
    //     name: "name",
    // },
    {
        img: "Images/work/Untitled.mp4",
        name: "name",
    },
    {
        img: "Images/work/proleagu.mp4",
        name: "name",
    },


]

export const TeamData = [
    {
        img: "Images/team/AHMED-nft.jpeg",
        name: 'ahmed shehada',
        position: true,
        title: 'co-founder'

    },
    {
        img: "Images/team/MOOD-nft2.png",
        name: 'mahmoud shehada',
        position: true,
        title: 'co-founder and ceo'
    },
    {
        img: "Images/team/abas.jpeg",
        name: 'abbas saidi',
        position: true,
        title: 'manager'
    },
    {
        img: "Images/team/mustafa.png",
        name: 'Mustafa Elahi',
        position: false,
        title: 'Web Developer'

    },
    {
        img: "Images/team/Althaf-NFT3d.png",
        name: 'Althaf abdulla',
        position: false,
        title: 'graphic designer'

    }

]


