import { useState, useLayoutEffect } from 'react'

function useWinodw() {

    const [size, setSize] = useState([window.innerHeight, window.innerWidth])

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize([window.innerHeight, window.innerWidth])
        }
        window.addEventListener('resize', updateSize)
        updateSize()
    }, [])
    return size;
}

export default useWinodw