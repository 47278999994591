import { useEffect, useState } from "react";
import {
  BrowserRouter as Router, Routes, Route,
} from "react-router-dom";
import Home from "./pages/Home";
import Loder from "./pages/Loder";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load',()=>{
      setTimeout(() => {
      setLoading(false);
    }, 500)
    })
  }, [])

  return (
    <>

      <Router>
        <Routes>
          {loading ?
            <Route path="/" element={<Loder />} />
            : <Route path="/" element={<Home />} />
          }
        </Routes>
      </Router>


    </>
  );
}

export default App;
