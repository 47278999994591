import React from 'react'
import Footer from '../component/footer/Footer'
import Chat from '../component/header/chat/Chat'
import Navbar from '../component/header/navbar/Navbar'
import Topbar from '../component/header/topbar/Topbar'
import About from '../component/main/about/About'
import Blog from '../component/main/blog/Blog'
import Company from '../component/main/company/Company'
import Cultures from '../component/main/culture/Cultures'
import Team from '../component/main/team/Team'
import Service from '../component/main/what we do/Service'
// import Work from '../component/main/work/Work'

function Home() {
    return (
        <>

            <Navbar />
            <Topbar />
            <Chat />
            {/* <Work /> */}
            <About />
            <Service />
            <Cultures />
            <Blog />
            <Team />
            <Company />
            <Footer />
        </>
    )
}

export default Home